
import {defineComponent, onMounted, reactive, toRefs, computed, ref} from "vue";
import ApiArticle from "@/request/apis/api_article";
import ApiLogistics from "@/request/apis/api_logistics";
import {articleTypes, IArticleCategory, IArticleItem} from "@/models/article_model";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {IArticlePlatform, IArticlePlatformCate} from "@/models/logistics_article_model";
import {ICarItem} from "@/models/logistics_model";
import * as dayjs from 'dayjs'

interface IState {
  list: ICarItem[],
  total: number;
  loading: boolean;
  searches: any
  editItem?: ICarItem,
  articlePlatVisible: boolean
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },

  {
    title: '车辆',
    dataIndex: 'vehicle_travel_license_number',
    key: 'vehicle_travel_license_number',
    minWidth: 280
  },
  {
    title: '车辆图片',
    dataIndex: 'vehicle_images',
    key: 'vehicle_images',
    minWidth: 140
  },
  {
    title: '所有人',
    dataIndex: "vehicle_travel_owner",
    key: 'vehicle_travel_owner',
    minWidth: 150
  },
  {
    title: '业户名称',
    dataIndex: 'transportation_owner',
    key: 'transportation_owner',
    minWidth: 90
  },
  // {
  //   title: '经营许可证号',
  //   dataIndex: 'road_transportation_permit_card',
  //   key: 'road_transportation_permit_card',
  // },
  {
    title: '行驶证',
    dataIndex: 'vehicle_travel_time',
    key: 'vehicle_travel_time',
    minWidth: 210
  },
  {
    title: '道路运输证',
    dataIndex: 'vehicle_travel_license_images',
    key: 'vehicle_travel_license_images',
    minWidth: 210
  },

  // {
  //   title: '最近年检记录',
  //   dataIndex: 'vehicle_travel_time',
  //   key: 'vehicle_travel_time',
  // },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  // {
  //   title: '操作',
  //   dataIndex: 'action',
  //   key: 'action',
  //   width: 160
  // },
];

export default defineComponent({
  name: 'ArticleCateList',
  components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView},
  setup() {
    const platSet = ref();
    const state: IState = reactive({
      platformList: [],
      list: [],
      total: 0,
      loading: false,
      editItem: undefined,
      searches: {
        page: 1,
        limit: 10,
        keywords: ""
      },
      articlePlatVisible: false
    })


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    //获取平台列表
    const getList = async () => {
      let params = {...state.searches}
      params.page_no = params.page;
      params.page_size = params.limit;
      params.keyword = params.keywords;
      StorageUtil.setParams(params)
      delete params.total
      delete params.pid
      state.loading = true
      const res = await ApiLogistics.carList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.list = res.data?.list || [];
        state.total = res.data?.count || 0;
      }
    }

    // 去新增编辑
    const goSet = (item?: IArticlePlatform) => {
      let params = {}
      if (item) {
        params = {id: item.id}
      }
      router.push({path: '/articleCate/set', query: params}).then()
  }
    // 详情
    const goDetail = (item: IArticleItem) => {
      router.push({path: '/article/detail', query: {id: item.id}}).then()
    }

    // 添加分类
    const addCate = () => {
      goSet()
    }

    // 修改状态
    const changeStatus = async (item: IArticlePlatformCate) => {
      let params = {
        "id": item.id,
        "status": item.status ? 0 : 1
      }
      state.loading = true
      const res = await ApiLogistics.articleCateStatusChange(params)
      state.loading = false
      if (res && res.error_code === 0) {
        getList()
      }
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      goSet,
      goDetail,
      pagination,
      handleTableChange,
      articleTypes,
      onSearch,
      addCate,
      changeStatus,
      dayjs
    }
  }
})
